import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H1 } from 'primitives/appTitle';
import HeroTitle from 'components/hero/heroTitle';

import { useEmptyJoinForm } from './ProspectPhotographerDTOValidation';
import { JoinPageTranslations } from './joinPageTranslations';
import { ShText } from '@shoootin/translations';
import JoinForm from './joinForm';
import { graphql } from 'gatsby';
import { JoinPagePhotoQuery } from '../../appGraphQLTypes';
import { getFluidImage } from '../../appGatsbyImageUtils';

const JoinPage = ({ data }: { data: JoinPagePhotoQuery }) => {
  const heroImage = getFluidImage(data.heroImage!);
  const emptyForm = useEmptyJoinForm();
  return (
    <MainLayout
      className="page-photograph"
      showContact={false}
      pageName="photographerJoin"
    >
      <HeroTitle imgSrc={heroImage}>
        <H1 modifier="hero-title">
          <ShText message={JoinPageTranslations.pageTitle} />
        </H1>
      </HeroTitle>
      <JoinForm initialValues={emptyForm} />
    </MainLayout>
  );
};

export default JoinPage;

export const query = graphql`
  query JoinPagePhoto {
    heroImage: file(relativePath: { eq: "photographer_header.jpg" }) {
      ...AppFluidHeroImageFragment
    }
  }
`;
